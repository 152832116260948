import React, { useEffect, useState, useRef, Component } from 'react';
import { Container, Row, Col, Table, Button, Modal, Form, Card, Image, Dropdown } from 'react-bootstrap';
import { SideNav } from '../../features/sidenav/SideNav';
import { Header } from '../../features/mobile_header/Header';
import Chart from "react-apexcharts";
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { resetDeviceState, selectDevice } from '../../features/devices/deviceSlice';
import { selectCryptoCurrencySlice } from '../../features/cryptocurrency/cryptocurrencySlice';
import { currencyListThunk } from '../../features/cryptocurrency/cryptocurrencyThunks';
import { cancelDeviceSubsriptionThunk, deviceListThunk, deviceSubscribeThunk, getDeviceDetailThunk, updateDeviceThunk } from '../../features/devices/deviceThunks';
import serializer from 'form-serialize';
import { useAuth } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { Select as VirtualizeSelect } from 'react-select-virtualized';
import AsyncSelect from "react-select/async";
import { createFilter } from "react-select";
import CryptoOptionList from '../../components/CryptoOptionList';
import { FixedSizeList as List } from "react-window";
import useDidMountHook from '../../hooks/useDidMountHook';
import { loadStripe } from '@stripe/stripe-js';
import { subscribeCustomerThunk } from '../../features/stripe/stripeThunks';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../../components/CheckoutForm';
import { selectAuth, AUTH_EVENTS, resetAuthState } from '../../features/auth/authSlice';
import { resetStripeState } from '../../features/stripe/stripeSlice';

export default function UpdateDevice({props}) {
	
    const { REACT_APP_PAYMENT_PKEY } = process.env;
    const stripePromise = loadStripe(REACT_APP_PAYMENT_PKEY);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { device_id } = useParams();

	var [user] = useAuth();
    const toastId = useRef();
    const [toastGet, toastSet] = useState(toastId.current);

	const { status, event, message, data } = useSelector(selectDevice);
	const { status: deviceStatus, event : deviceEvent, message: deviceMessage, data: deviceData } = useSelector(selectAuth);
	const { status: currencyStatus , event: currencyEvent, message: currencyMessage, data: currencyData } = useSelector(selectCryptoCurrencySlice);
	const form = useRef(null);

	const available_screens = [
		{ value: '1', src: require('../../assets/images/screens/screen-1.png'), name: 'Screen 1' },
		{ value: '2', src: require('../../assets/images/screens/screen-2.png'), name: 'Screen 2' },
		{ value: '3', src: require('../../assets/images/screens/screen-3.png'), name: 'Screen 3' },
		{ value: '4', src: require('../../assets/images/screens/screen-4.png'), name: 'Screen 4' },
		{ value: '5', src: require('../../assets/images/screens/screen-5.png'), name: 'Screen 5' },
		{ value: '6', src: require('../../assets/images/screens/screen-6.png'), name: 'Screen 6' },
		{ value: '7', src: require('../../assets/images/screens/screen-7.png'), name: 'Screen 7' },
		{ value: '8', src: require('../../assets/images/screens/screen-8.png'), name: 'Screen 8' },
	]

	// const available_interval = [
	// 	{ label: '1min', value: '1min'},
	// 	{ label: '5min', value: '5min'},
	// 	{ label: '1h', value: '1h'},
	// 	{ label: '1d', value: '1d'},
	// 	{ label: '1w', value: '1w'},
	// 	{ label: '1w', value: '1w'},
	// 	{ label: '1m', value: '1m'},
	// 	{ label: '3m', value: '3m'},
	// 	{ label: '6m', value: '6m'},
	// 	{ label: '12m', value: '12m'},
	// ]

	// let available_crypto_currency = [
	// 	{ name: 'Bitcoin', value: 'BTC' },
	// 	{ name: 'XRP', value: 'XRP' },
	// 	{ name: 'Etherium', value: 'ETH' },
	// 	{ name: 'Siacoin', value: 'SC' },
	// ]
	const primary_currency = [
		{ label: 'USD', value: 'USD' },
		// { label: 'AUD', value: 'AUD' },
	]


	// const dropdownRef = useRef(null);
    // const [isOpen, setIsOpen] = useState(false); 
    // const dropDownShow = () => { 
    //     setIsOpen(!isOpen); 
    // }; 


	const [selectedDevice, setSelectedDevice] = useState({});
	const [cryptoCurrenciesId, setCryptoCurrenciesId] = useState([]);
	const [showCancelSubscription, setShowCancelSubscription] = useState(false);
	const [showUpgradeSubscription, setShowUpgradeSubscription] = useState(false);


	const handleClose = () => setShowCancelSubscription(false);
	const handleShow = () => setShowCancelSubscription(true);

	const handleUpgradeClose = () => setShowUpgradeSubscription(false);
	const handleUpgradeShow = () => setShowUpgradeSubscription(true);

	// let devices_list = [];
	useDidMountHook(async () => {
		if (device_id) {
			await dispatch(getDeviceDetailThunk({ device_id: device_id }))
		}
		if (user) {
			await dispatch(currencyListThunk({ user_id: user?.id }))
		}
	});

	useEffect(() => {
		// if (status === "initial") {
		// 	console.log(123, device_id);
		// 	dispatch(getDeviceDetailThunk({ device_id: device_id }))
		// }

		// if (currencyStatus === 'initial') {
		// 	dispatch(currencyListThunk({ user_id: user?.id }))
		// }
		if (event === 'update') {
			if (status === "update_success") {
				toast.success('Successfuly updated device', {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: true,
					draggable: true,
					theme: "light",
				});
	
				let id = user;
				// dispatch(getDeviceDetailThunk({ device_id: device_id }))
				dispatch(currencyListThunk({ user_id: user?.id }))
				
				navigate('/devices');
			}
			
		}
		if (event === 'detail_device') {
			if (status === "loaded") {
				setSelectedDevice(data.length > 0 ? data[0] : {});
			}
		}
		if (event === "device_subsribe") {
            if (status === "loading") {
                toastSet(toast.loading("Please wait loading...", {
                    isLoading: true,
                }))
            }
			if (status === "error") {
				toast.error(toastGet, {
					type: "error",
					isLoading: false,
					render: message,
					autoClose: 2000,
					theme: "colored",
				})
			}
			if (status === "success") {
                toast.update(toastGet, {
                    type: "success",
                    render: 'Successfuly updated device',
                    isLoading: false,
                    autoClose: 2000
                })
	
				// let id = user;
				// dispatch(getDeviceDetailThunk({ device_id: device_id }))
				dispatch(currencyListThunk({ user_id: user?.id }))
				
                setTimeout(() => { navigate("/devices", { replace: true, preventScrollReset: true }) }, 100) 
				// window.location.href = '/devices';
			}
		}
	
		// const handleOutSideClick = (event) => {
		// 	if (!dropdownRef.current?.contains(event.target)) {
		// 	  setIsOpen(false);
		// 	}
		//   };
	  
		// window.addEventListener("mousedown", handleOutSideClick);
	
		// return () => {
		// 	window.removeEventListener("mousedown", handleOutSideClick);
		// };
		
		if (event === "cancel_subsribe") {
            if (status === "loading") {
                toastSet(toast.loading("Please wait loading...", {
                    isLoading: true,
                }))
            }
			if (status === "error") {
				toast.error(toastGet, {
					type: "error",
					isLoading: false,
					render: message,
					autoClose: 2000,
					theme: "colored",
				})
			}
			if (status === "success") {
                toast.update(toastGet, {
                    type: "success",
                    render: message,
                    isLoading: false,
                    autoClose: 2000
                })
                setTimeout(() => { navigate("/devices", { replace: true, preventScrollReset: true }) }, 100) 
			}
		}
		
	}, [status, event])

	const submitUpdateForm = () => {
		// navigate('/register/device')
		if (selectedDevice && selectedDevice.screen === "4" && selectedDevice.cryptoCurrency.length > 4) {
			toast.warning('You can only select 4 crypto currencies', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			});
		} else if (selectedDevice && selectedDevice.screen === "4" && selectedDevice.cryptoCurrency.length < 4) {
			toast.warning('Please select 4 crypto currencies', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "light",
			});
		} else {
			dispatch(updateDeviceThunk(selectedDevice));
		}
	}

	const selectCryptoCurrency = (currencies) => {
		// if (selectedDevice && selectedDevice.cryptoCurrency && selectedDevice.cryptoCurrency.length > 0 && selectedDevice.cryptoCurrency.find((curr) => currency === curr)) {
		// 	setSelectedDevice({ ...selectedDevice, cryptoCurrency: selectedDevice.cryptoCurrency.filter((curr) => curr != currency) });
		// } else {
		// 	if (!selectedDevice.cryptoCurrency) {
		// 		setSelectedDevice({ ...selectedDevice, cryptoCurrency: [currency] })
		// 	} else {
		// 		setSelectedDevice({ ...selectedDevice, cryptoCurrency: [...selectedDevice.cryptoCurrency, currency] })
		// 	}
		// }
		if (currencies && currencies.length > 0) {
			// console.log(currencyData.data.map(data => data.id.includes(currencies.map(currency => currency.value))));
			const currencySymbol = currencyData.data.filter(data => { 
				return currencies.find(currency => data.id == currency.value);
			}).map(data => data.symbol);
			setSelectedDevice({ ...selectedDevice, cryptoCurrency: currencySymbol, cryptoCurrencyId: currencies.map(currency => currency.value) })
		} else if (currencies) {
			const currencySymbol = currencyData.data.filter(data => { 
				return data.id == currencies.value;
			}).map(data => data.symbol);
			setSelectedDevice({ ...selectedDevice, cryptoCurrency: currencySymbol, cryptoCurrencyId: currencies.value })
		}
	}

    const submitSubscribe = async (pm) => {
        await dispatch(deviceSubscribeThunk({email: user?.email, deviceId: selectedDevice?.device_id, payment_method: pm?.paymentMethod?.id}));
        // await dispatch(subscribeCustomerThunk(token?.token?.id ?? '', cardData));
        // await connectDevice();
    }

	const cancelSubscription = async () => {
		await dispatch(cancelDeviceSubsriptionThunk({subscriptionId: selectedDevice?.subscriptionId}));
	}


	const setScreenValue = (screen_id) => {
		if (screen_id === '5' || screen_id === '6' || screen_id === '7' || screen_id === '8') {
			setSelectedDevice({...selectedDevice, cryptoCurrencyId: [1], cryptoCurrency: ['BTC'], screen: screen_id});
		} else {
			setSelectedDevice({...selectedDevice, screen: screen_id});
		}
	}

	
	class MenuList extends Component {
		render() {
		const height = 35;
		const { options, children, maxHeight, getValue } = this.props;
		const [value] = getValue();
		const initialOffset = options.indexOf(value) * height;
	
		return (
				<List
				height={maxHeight}
				itemCount={children.length}
				itemSize={height}
				initialScrollOffset={initialOffset}
				>
				{({ index, style }) => <div style={style}>{children[index]}</div>}
				</List>
			);
		}
	}

	// style={{backdropFilter: `brightness(${rangeValue}%)`}}
	return (
		<>
			<Header page={'update-device'} />
			
			{(() => {
				if (status === "loaded" && selectedDevice && selectedDevice.name != undefined) {
					return (
						<>
							<Container fluid className="element-page update-device h-100 pt-5 pb-5">
								<Row>
									<Col md={12}>
										Update Your Device
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Label key={0}>Device Name</Form.Label>
										<Form.Control type="text" name="name" value={selectedDevice.name} onChange={(e) => setSelectedDevice({ ...selectedDevice, name: e.target.value })} />
									</Col>
									<Col md={6} sm={12}>
										<Form.Label key={1}>Device ID</Form.Label>
										<Form.Control type="text" name="name" disabled={true} readOnly={true} value={selectedDevice.device_id} />
									</Col>
								</Row>
								<Row>
									<Col md={12} sm={12}>
										<Form.Label key={2}>Screen</Form.Label>
									</Col>
									<Row className="justify-content-center">
										<Col xl={1} className="d-none d-xl-block"></Col> {/* Empty column for centering on large screens only */}
										{available_screens.map((screen, i) => (
											<Col xl={2} lg={4} md={6} sm={12}>
												<Form.Group onClick={() => setScreenValue(screen.value)} className={`form-group ` + (selectedDevice.screen === screen.value ? 'selected' : '')}>
													<Image src={screen.src}></Image><br />
													<Form.Label key={i}>{screen.name}</Form.Label>
												</Form.Group>
											</Col>
										))}
										<Col xl={1} className="d-none d-xl-block"></Col> {/* Empty column for centering on large screens only */}
									</Row>
								</Row>
								<Row>
									<Col md={(selectedDevice.screen === '5' || selectedDevice.screen === '6' || selectedDevice.screen === '7' || selectedDevice.screen === '8' ? 3 : 6)} sm={12}>
										<Form.Label key={4}>Crypto Currency</Form.Label>
										{(() => {
											if (currencyStatus === "loaded") {
												const availableCryptoCurrency = currencyData.data.map((currency, i) => {
													return { label: currency.name + ' (' + currency.symbol + ')', value: currency.id};
												});
												
												const loadOptions = (inputValue, callback) => {
													setTimeout(() => {
													callback(availableCryptoCurrency);
													}, 1000);
												};
												if (selectedDevice && (selectedDevice.screen === '5' || selectedDevice.screen === '6' || selectedDevice.screen === '7' || selectedDevice.screen === '8')) {
													return (
														<>
															<Select options={[{value: 1, label: 'Bitcoin (BTC)'}]}
															defaultValue={{value: 1, label: 'Bitcoin (BTC)'}}
															isDisabled={true}
															onChange={(e) => selectCryptoCurrency(e.target)} />	
														</>
													)
												} else if (selectedDevice && selectedDevice.screen === '4') {
													return (
														<>
															<AsyncSelect components={{ MenuList }} 
																cacheOptions 
																isMulti={true}
																defaultOptions
																defaultValue={
																	selectedDevice?.cryptoCurrencyId && selectedDevice?.cryptoCurrencyId.length > 0 ? 
																	availableCryptoCurrency.filter(option => selectedDevice?.cryptoCurrencyId?.includes(option.value)) : ''
																}
																loadOptions={loadOptions}
																filterOption={createFilter({ ignoreAccents: false })}
																onChange={(e) => selectCryptoCurrency(e) }
																/>
														</>
													)
												} else {
												
													return (
														<>
															<VirtualizeSelect options={availableCryptoCurrency}
																	defaultValue={
																		selectedDevice?.cryptoCurrencyId && selectedDevice?.cryptoCurrencyId.length > 0 ?  availableCryptoCurrency.find(option => 
																		option.value === selectedDevice?.cryptoCurrencyId[0]) : {}
																	}																	
																	onChange={(e) => selectCryptoCurrency(e)}
																		/>
														</>
													)
												}
											}							
										})()}	
									</Col>
									
									{(() => {
										if (currencyStatus === "loaded") {
											const available_interval = currencyData.interval_items.map((interval, i) => {
												return { label: interval, value: interval};
											});
											if (selectedDevice.screen === '5' || selectedDevice.screen === '6' || selectedDevice.screen === '7' || selectedDevice.screen === '8') {
												return (
													<>
														<Col md={3} sm={12}>
															<Form.Label key={''}>Interval</Form.Label>
															<Select options={available_interval}
																defaultValue={available_interval.find(interval => interval.value === selectedDevice.interval)}
																onChange={(e) => setSelectedDevice({ ...selectedDevice, interval: e.value })} />		
														</Col>
													</>
												)
													
											}	
										}					
									})()}		
									
									<Col md={6} sm={12}>
										<Form.Label key={5}>Primary Currency</Form.Label>
										
										<Select options={primary_currency}
												defaultValue={primary_currency.find(currency => currency.value === selectedDevice?.primaryCurrency)}
												onChange={(e) => setSelectedDevice({ ...selectedDevice, primaryCurrency: e.value })} />		
									</Col>
								</Row>
								<Row>
									<Col md={6} sm={12}>
										<Form.Label key={6}>Mode</Form.Label>

										<Row>
											<Col md={4}>
												<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, mode: 'CryptoX' })} className={`form-group with-icon avatar ` + (selectedDevice.mode === 'CryptoX' ? 'selected' : '')}>
													<div className='cryptox avatar'></div>
													<Form.Label key={7}>
														CryptoX
													</Form.Label>
												</Form.Group>
											</Col>
											<Col md={4}>
												<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, mode: 'Light' })} className={`form-group with-icon avatar ` + (selectedDevice.mode === 'Light' ? 'selected' : '')}>
													<div className='light avatar'></div>
													<Form.Label key={8}>
														Light
													</Form.Label>
												</Form.Group>
											</Col>

											<Col md={4}>
												<Form.Group onClick={() => setSelectedDevice({ ...selectedDevice, mode: 'Dark' })} className={`form-group with-icon avatar ` + (selectedDevice.mode === 'Dark' ? 'selected' : '')}>
													<div className='dark avatar'></div>
													<Form.Label key={9}>
														Dark
													</Form.Label>
												</Form.Group>
											</Col>
										</Row>
									</Col>
									<Col md={6} sm={12}>
										<Form.Label key={10}>Brightness {selectedDevice?.brightness}%</Form.Label>
										<Form.Range onChange={(e) => setSelectedDevice({ ...selectedDevice, brightness: e.target.value })} value={selectedDevice?.brightness} name='brightness' min="0" max="100" />
									</Col>
									
								</Row>
								<Row className='align-items-center justify-content-center'>
									<Col xl={6} lg={3} md={12} className="text-left text-md-right align-items-center d-flex d-lg-block justify-content-between">
                                    	<Button className="btn-sm" variant="secondary" onClick={() => navigate('/devices')}>Cancel</Button>
										<Button className='btn-sm d-block d-lg-none' variant="primary" onClick={submitUpdateForm}>Update</Button>
									</Col>
									<Col xl={6} lg={9} md={12} className="text-start text-md-end text-sm-center d-flex d-lg-block justify-content-center justify-content-md-end flex-wrap align-items-sm-center">
										
										{(() => {
											if (selectedDevice.isPaidSubscription && selectedDevice.paymentStatus === 'Paid') {
												return (
													<>
														<Button className='btn-sm' variant="cancel" onClick={handleShow}>Cancel Subscription</Button>
													</>
												)
											} else if (selectedDevice.paymentStatus == 'Free') {
												return (
													<>
														<Button className='btn-sm' variant="upgrade" onClick={handleUpgradeShow}>Upgrade Subscription</Button>
													</>
												)
											}
										})()}										
										<Button className='btn-sm d-none d-lg-inline-block' variant="primary" onClick={submitUpdateForm}>Update</Button>
									</Col>
								</Row>
							</Container>
							
							
							<Modal show={showCancelSubscription} onHide={handleClose} size="md" fullscreen={'lg-down'}>
								<Modal.Header closeButton>
									<Modal.Title>Confirm</Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<Row>
										<Col>
											<Form.Label key={11}>Please confirm if you would like to proceed with the cancellation of your subscription.</Form.Label>
										</Col>
									</Row>
								</Modal.Body>
								<Modal.Footer>
									<Button className='btn-sm' variant="secondary" onClick={handleClose} >Cancel</Button>
									<Button className='btn-sm' variant="primary" onClick={cancelSubscription} >Confirm</Button>
								</Modal.Footer>
							</Modal>
							<Modal show={showUpgradeSubscription} onHide={handleUpgradeClose} size="xl" fullscreen={'lg-down'}>
								<Modal.Header closeButton>
									<Modal.Title>Payment</Modal.Title>
								</Modal.Header>
								<Modal.Body className='form-account'>
									<Row className='align-items-center pricing'>
										<Col className='each'>
											<label>
                                                <h2>$15.00/mo</h2>

                                                <ul>
                                                    <li>Enjoy real-time data refreshes with up to 15-second latency for the most current information.</li>
                                                </ul>
                                                <input type="radio" value={'Monthly'} className="hidden" name="subscriptionType" />
                                            </label>
											<span>You will be registered in this subscription.</span>
										</Col>
										<Col>
											<Elements stripe={stripePromise}>
												<CheckoutForm submitSubscribe={submitSubscribe}/>
											</Elements>
										</Col>
									</Row>
								</Modal.Body>
								<Modal.Footer>
									<Button className='btn-sm' variant="secondary" onClick={handleUpgradeClose} >Cancel</Button>
								</Modal.Footer>
							</Modal>
						</>

					)
				}
				
				if (status === "loading") {
					return (
						<>
							<Container fluid className="element-page update-device h-100 pt-5 pb-5">
								<Row>
									<Col lg={12}>Loading...</Col>
								</Row>
							</Container>
						</>
					)
				}
				if (status === "error") {
					
					return (
						<>
							<Container fluid className="element-page update-device h-100 pt-5 pb-5">
								<Row>
									<Col md={12} className='text-center'>
										No Device Found <a className='link' onClick={() => navigate('/devices')}> Go back</a>
									</Col>
								</Row>
							</Container>
						</>
					)
				}
			})()}
		</>
	)
}

